export default defineNuxtRouteMiddleware(async () => {
  const { applicationData } = storeToRefs(
    useApplicationDataStore(FORM_STORE_ID),
  );
  const { isAuthenticated, goToUserDefaultPage } = await useAuth();
  if (!applicationData.value && !isAuthenticated.value) {
    return navigateTo("/register");
  } else if (!applicationData.value) {
    return goToUserDefaultPage();
  }
});
